<template>
    <div class="overlay">
        <v-container fluid class="h-full">
            <v-row align="center" justify="center" class="h-full">
                <v-col>
                    <div class="dialog">
                        <v-row justify="center" align="center" class="pa-6 title">
                            {{$t('dialogs.password_placeholder')}}
                        </v-row>
                        <v-row justify="center" align="center" class="pa-6 py-0 title">
                            <v-col>
                                <v-text-field :error-messages="errors.password"
                                              type="password"
                                              label="Password"
                                              color="white"
                                              v-model="password"
                                />
                            </v-col>
                        </v-row>
                        <v-row class="pb-6 d-flex wrap px-5">
                            <v-col cols="12" @click.stop="$emit('confirm', {password})">
                                    <span  class="choose yes pt-3 pb-3 pl-7 pr-7 d-block text-center">
                                        {{$t('dialogs.confirm_and_delete')}}
                                    </span>
                            </v-col>
                            <v-col cols="12" @click.stop="$emit('cancel')">
                                    <span  class="choose no pt-3 pb-3 px-16 d-block text-center">
                                        {{$t('dialogs.cancel')}}
                                    </span>
                            </v-col>
                        </v-row>
                    </div>
                </v-col>
            </v-row>
        </v-container>

    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    name: "ConfirmPasswordDialog",
    props: {
      errors: {
          type: Object,
          default: {},
      }
    },
    data() {
        return {
            password: '',
        }
    },
    computed: {
        ...mapState({
            user: (state) => state.user.user.data,
        }),
    },
    mounted() {
        this.$mixpanel.identifyUser(this.user.email);

        this.$mixpanel.popupViewed('Delete account');

        // this.$mixpanel.peopleSet({
        //     attempt_delete_account: 1, // Sets to 1 if the property doesn't already exist
        // });

        // // Increment the delete attempt count
        // this.$mixpanel.peopleIncrement('attempt_delete_account', 1);
    },
}
</script>

<style scoped lang="scss">
@import '@/sass/modules/_variables';

.choose{
    border: 1px solid white;
    border-radius: 13px;
    transition: 0.3s;
    cursor: pointer;
}
.choose:active{
    border: 1px solid white;
    border-radius: 13px;
    background: rgba(255,255,255,0.3);
}
.link {
    text-decoration: none;
    color: white;
    font-size: 1.3em;
}

.overlay {
    position: fixed;
    background: rgba(52, 52, 65, 0.88);
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.dialog {
    background: $accent-background;
    min-height: 140px;
    border-radius: 14px;
}
.title{
    font-size: 1.3em;
}
</style>
